import { HttpClient } from "@parkschannel/next-utils";
export class ParksApi {
    getParksByType(params, parksParams) {
        var ref, ref1;
        return this.client.get("".concat(this.baseUrl), {
            params: {
                page: params.page,
                limit: params.limit,
                search: params.search,
                random: parksParams.random || null,
                "filter.parkType": parksParams.type ? "$eq:".concat(parksParams.type) : null,
                ...(parksParams === null || parksParams === void 0 ? void 0 : (ref = parksParams.states) === null || ref === void 0 ? void 0 : ref.length) ? {
                    "filter.states": "$in:".concat(parksParams.states.join(","))
                } : {},
                ...(parksParams === null || parksParams === void 0 ? void 0 : (ref1 = parksParams.tags) === null || ref1 === void 0 ? void 0 : ref1.length) ? {
                    "filter.tags.name": "$in:".concat(parksParams.tags.join(","))
                } : {},
                ...(parksParams === null || parksParams === void 0 ? void 0 : parksParams.sortBy) ? {
                    sortBy: parksParams.sortBy
                } : {}
            }
        }, false);
    }
    getParks(paginationParams) {
        return this.client.get("".concat(this.baseUrl), {
            params: {
                ...paginationParams
            }
        }, false);
    }
    getPark(idOrSlug) {
        return this.client.get("".concat(this.baseUrl, "/").concat(idOrSlug), {}, false);
    }
    async getParkVideos(idOrSlug, params) {
        return this.client.get("".concat(this.baseUrl, "/").concat(idOrSlug, "/popular_videos"), {
            params
        }, false);
    }
    async getSponsorsVideos(idOrSlug, sponsorId) {
        return this.client.get("".concat(this.baseUrl, "/").concat(idOrSlug, "/sponsor_videos?sponsor=").concat(sponsorId), {}, false);
    }
    async getParkAuthors(idOrSlug, params) {
        return this.client.get("".concat(this.baseUrl, "/").concat(idOrSlug, "/popular_authors"), {
            params
        }, false);
    }
    constructor(client = new HttpClient()){
        this.client = client;
        this.baseUrl = "/api/parks";
    }
}
