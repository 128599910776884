import dynamic from "next/dynamic";
export const DynamicHomeMap = dynamic(()=>import("./HomeMap"), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./HomeMap")
            ]
    },
    ssr: false,
    suspense: true
});
