import Link from 'next/link';
import { ArrowRight, SimpleImage, Typography } from '@parkschannel/ui';
import React from 'react';
import classNames from 'classnames';

interface CTACardBase {
  id: number;
  img: string;
  title: string;
  text: string;
  disabled?: boolean;
}

type CTACardWithLink = CTACardBase & {
  linkHref: string;
  linkText: string;
};

type CTACardWithButton = CTACardBase & {
  onClick: () => void;
  buttonText: string;
};

export type CTACardProps = CTACardWithLink | CTACardWithButton;

export const CTACard: React.FC<{
  data: CTACardProps;
  renderImage?: (data: CTACardProps) => React.ReactNode;
}> = ({ data, renderImage }) => {
  return (
    <article className="min-w-[293px]">
      <div className="relative h-[244px] w-full rounded-2xl overflow-hidden mb-6">
        {renderImage ? (
          renderImage(data)
        ) : (
          <SimpleImage
            src={data.img}
            className="object-cover"
            alt={data.title}
            fill
          />
        )}
      </div>

      <div className="flex flex-col">
        <Typography variant="h4" className="mb-1 text-neutral-900 lg:mb-2">
          {data.title}
        </Typography>

        <Typography variant="bodyXL" className="text-neutral-600 mb-4">
          {data.text}
        </Typography>

        {isCTAWithButton(data) ? (
          <button
            disabled={data.disabled}
            className={classNames(
              'flex items-center gap-1 text-sm font-semibold',
              {
                'text-neutral-600': !data.disabled,
                'text-neutral-200': data.disabled,
              }
            )}
            onClick={data.onClick}
          >
            {data.buttonText}{' '}
            <ArrowRight
              className={classNames('w-6 h-6', {
                'text-primary-500': !data.disabled,
                'text-primary-100': data.disabled,
              })}
            />
          </button>
        ) : (
          <Link
            href={data.disabled ? '#' : data.linkHref}
            className={classNames(
              'flex items-center gap-1text-sm font-semibold',
              {
                'text-neutral-600': !data.disabled,
                'text-neutral-200': data.disabled,
              }
            )}
          >
            {data.linkText}{' '}
            <ArrowRight
              className={classNames('w-6 h-6', {
                'text-primary-500': !data.disabled,
                'text-primary-100': data.disabled,
              })}
            />
          </Link>
        )}
      </div>
    </article>
  );
};

function isCTAWithButton(
  data: CTACardWithButton | CTACardWithLink
): data is CTACardWithButton {
  return (data as CTACardWithButton).onClick !== undefined;
}
