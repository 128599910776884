import { getEnvVariables } from "@parkschannel/next-utils";
export class WordpressApi {
    getParkArticles(parkSlug) {
        return fetch("".concat(this.baseUrl, "/wp-json/wp/v2/park_content/?park_name=").concat(parkSlug, "&_embed"), {
            method: "GET"
        }).then((res)=>res.json());
    }
    constructor(baseUrl = getEnvVariables()["WORDPRESS_URL"]){
        this.baseUrl = baseUrl;
    }
}
