import { useQuery } from "@tanstack/react-query";
import { VideoApi } from "../../Api";
export const usePopularCreators = ()=>{
    const videoApi = new VideoApi();
    const usePopularCreatorsQuery = (pagination)=>useQuery([
            "popular-creators",
            pagination
        ], ()=>videoApi.getPopularCreators(pagination));
    return {
        usePopularCreatorsQuery
    };
};
