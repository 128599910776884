import 'react-alice-carousel/lib/alice-carousel.css';
import React from 'react';
import AliceCarousel, { Props as AliceProps } from 'react-alice-carousel';

export const Carousel = React.forwardRef<AliceCarousel, AliceProps>(
  ({ items, ...rest }, ref) => {
    return (
      <AliceCarousel
        ref={ref}
        mouseTracking
        items={items}
        controlsStrategy="alternate"
        keyboardNavigation={true}
        disableButtonsControls={true}
        infinite
        {...rest}
      />
    );
  }
);
