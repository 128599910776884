import { useQuery } from "@tanstack/react-query";
import { ParksApi } from "../Api/Parks";
import { WordpressApi } from "../Api";
import { useConfig } from "../Config/ConfigContext";
export const useParks = ()=>{
    const { wordpressUrl  } = useConfig();
    const parkApi = new ParksApi();
    const wordpressApi = new WordpressApi(wordpressUrl);
    const useParksQuery = (pagination, parksParams, config)=>useQuery([
            "parks",
            pagination,
            parksParams
        ], ()=>parkApi.getParksByType(pagination, parksParams), {
            ...config
        });
    const useParkQuery = (id)=>useQuery([
            "parks",
            id
        ], async ()=>await parkApi.getPark(id));
    const useParkArticles = (slugOrId)=>useQuery([
            "wordpress",
            "park-articles",
            slugOrId
        ], async ()=>wordpressApi.getParkArticles(slugOrId));
    const useSponsorVideos = (id, sponsorId, config)=>{
        return useQuery([
            "videos",
            "park",
            "sponsor",
            id,
            sponsorId
        ], async ()=>await parkApi.getSponsorsVideos(id, sponsorId), {
            keepPreviousData: true,
            ...config
        });
    };
    const useParkVideos = (id, pagination, config)=>{
        return useQuery([
            "videos",
            "park",
            id,
            pagination
        ], async ()=>await parkApi.getParkVideos(id, pagination), {
            keepPreviousData: true,
            ...config
        });
    };
    const useParkAuthors = (id, pagination, config)=>{
        return useQuery([
            "authors",
            "park",
            id,
            pagination
        ], async ()=>await parkApi.getParkAuthors(id, pagination), {
            keepPreviousData: true,
            ...config
        });
    };
    return {
        useParksQuery,
        useParkQuery,
        useParkVideos,
        useParkAuthors,
        useParkArticles,
        useSponsorVideos
    };
};
